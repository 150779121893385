//https://accelerometerdemo.netlify.app/
//https://developer.mozilla.org/en-US/docs/Web/API/Touch_events


// console.log('%cHo🎅🏿ho🎅🏽ho!🎅🏽🎄🎇 \n%c ;-)',
//   'color: #1c87c9; background: #ccc; font-size: 160px;',
//   'color: #8ebf42; padding:1rem; background: # 666; font - size: 20 px;'
// );






document.addEventListener('DOMContentLoaded', function () {
  
  /* eventlistern for ligting talks form mialchimp */
  let radio1 = document.getElementById('mce-MMERGE60');
  let radio2 = document.getElementById('mce-MMERGE61');
  
  let textarea = document.getElementById('mce-MMERGE7');
  if (radio1) {
    //alert(radio.checked);
    radio1.addEventListener('change', function () {
      if (radio1.checked == true) {
        textarea.classList.add('d-none');
        textarea.classList.add('d-none');
      } 
    })
    radio2.addEventListener('change', function () {
      if (radio2.checked == true) {
        textarea.classList.remove('d-none');
      }
    })
  } 
  
  
  
  
  let body_ = document.body;
  var st = setTimeout(function () {
    body_.classList.add('flash');
  }, 1000);

  var st1 = setTimeout(function () {
    body_.classList.remove('flash');
  }, 5000);
});

var output = '';
function createEvent(type, detail) {
  return new CustomEvent(type, { detail });
}
function getMaxAcceleration(event) {
  let max = 0;
  if (event.acceleration) {
    for (const key of ['x', 'y', 'z']) {
      const value = Math.abs(event.acceleration[key] ?? 0);
      if (value > max)
        max = value;
    }
  }
  return max;
}
export class Shake extends EventTarget {
  #approved;
  #threshold;
  #timeout;
  #timeStamp;
  constructor(options) {
    super();
    const { threshold = 15, timeout = 1000, } = options ?? {};
    this.#threshold = threshold;
    this.#timeout = timeout;
    this.#timeStamp = timeout * -1;
  }
  // @ts-ignore
  addEventListener(type, listener, options) {
    super.addEventListener(type, listener, options);
  }
  dispatchEvent(event) {
    return super.dispatchEvent(event);
  }
  // @ts-ignore
  removeEventListener(type, callback, options) {
    super.removeEventListener(type, callback, options);
  }
  async approve() {
    if (typeof this.#approved === 'undefined') {
      if (!('DeviceMotionEvent' in window))
        return this.#approved = false;
      try {
        if (typeof DeviceMotionEvent.requestPermission === 'function') {
          const permissionState = await DeviceMotionEvent.requestPermission();
          this.#approved = permissionState === 'granted';
        }
        else
          this.#approved = true;
      }
      catch {
        this.#approved = false;
      }
    }
    return this.#approved;
  }
  #handleDeviceMotion = (event) => {
    const diff = event.timeStamp - this.#timeStamp;
    if (diff < this.#timeout)
      return;
    const accel = getMaxAcceleration(event);
    if (accel < this.#threshold)
      return;
    this.#timeStamp = event.timeStamp;
    this.dispatchEvent(createEvent('shake', event));
  };
  async start() {
    const approved = await this.approve();
    if (!approved)
      return false;
    window.addEventListener('devicemotion', this.#handleDeviceMotion);
    return true;
  }
  stop() {
    window.removeEventListener('devicemotion', this.#handleDeviceMotion);
  }
}
////////////////////////////////////////////////////////////////////////////////

// Use:
 function shakeR33() {
  document.querySelector('body').classList.remove('ani');
  var st = setTimeout(function () {
    document.querySelector('body').classList.add('ani')
    //document.querySelector('body').classList.add('ani1');
  }, 700);
}
const shake = new Shake({ threshold: 20, timeout: 100 });

shake.addEventListener('shake', ev => {
  console.log('Shake!', ev.detail.timeStamp, ev.detail.acceleration);
  ///alert('shake');
  shakeR33();
});


const button = document.getElementById('start');
//const body = document.querySelector('body');
const body = document.querySelector('body');
body.addEventListener('click', async () => {
  const approved = await shake.start();
  const div = document.body.appendChild(document.createElement('div'));
  div.classList.add('msg');
  div.textContent = `${String(approved)}`;
  button.remove();
}, { once: true });

if (button) {
  button.addEventListener('click', async () => {
    const approved = await shake.start();
    const div = document.body.appendChild(document.createElement('div'));
    div.classList.add('msg');
    div.textContent = `Approved: ${String(approved)}`;
    button.remove();
  }, { once: true });

  // body.addEventListener('touchstart', async () => {
  //   const approved = await shake.start();
  //   const div = document.body.appendChild(document.createElement('div'));
  //   div.textContent = `Approved: ${String(approved)}`;
  //   button.remove();
  // }, { once: true });
 
}
// document.addEventListener('DOMContentLoaded', async function () {
//   const approved = await shake.start();
//   //console.log(approved);
//   const div = document.body.appendChild(document.createElement('div'));
//   div.classList.add('msg');
//   if (approved) {
//     div.textContent = `Approved: ${String(approved)}`;  
//     button.remove();
//   }
// });


function fireFirst(arcadeFire) {
  
  for (var i = 0; i <= arcadeFire.length; i++){
    const divEl = document.querySelector('.' + arcadeFire[i]);
    timeClasser(i, divEl);
    var st = setTimeout(function(){
      timeOuter(i, divEl, 10);
    },45 * i);
  }
}


 

// Function to randomly access array values and remove them
function delayShow(randomIndex,i) {
  
  var t = i / 576;
  var y = t > 0.5 ? 4 * Math.pow((t - 1), 3) + 1 : 4 * Math.pow(t, 3);
  setTimeout(() => {
    var divEl = '.div' + (randomIndex + 1);
    document.querySelector(divEl).classList.add('hide');
  }, (t * (i*8)));
}

function randomlyRemoveFromArray(arr) {
  // Continue until the array is empty
  var i = 0;
  while (arr.length > 0) {
    // Generate a random index
    const randomIndex = Math.floor(Math.random() * arr.length);
    
    // Access the value at the random index
    const randomValue = arr[randomIndex];

    // Remove the value from the array
    arr.splice(randomIndex, 1);
    
    delayShow(i,i); 
    // Do something with the randomly accessed and removed value
    i++;
  }
}



// timeOuter
function timeClasser(i, element) {
  if (element != null) {
    setTimeout(() => {
      element.classList.add('fire');
    }, 25 * i);  
  }
  //console.log(typeof element);
  
}
// timeOuter
function timeOuter(i, element, pause = 1000) {
  //console.log(element);
  if (element != null) {
    setTimeout(() => {
      //console.log(i);
      element.classList.remove('fire');
    }, pause * i);
  }
}

// Example usage
// const myArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
// //randomlyRemoveFromArray(myArray);






function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}


const Psize = '4.16%';
var increase = Math.PI / 100;


document.addEventListener("DOMContentLoaded", function () {

  var st = setTimeout(function () {
    document.querySelector('body').classList.add('ani');
    document.querySelector('body').classList.add('ani1');
  }, 10);

  const shakeButton = document.querySelector('.icn-shake');  
  shakeButton.addEventListener('click', function () {
    shakeR33();
  });

  // var st = setTimeout(function () {
  //   //const arcadeFire = ['div315', 'div316', 'div320', 'div321', 'div325', 'div326', 'div328', 'div329', 'div333', 'div334'];
  //   //const arcadeFire =['div371', 'div372', 'div349', 'div326', 'div282', 'div260', 'div237', 'div238', 'div214'];
  //   const arcadeFire = ['div387', 'div363', 'div339', 'div340', 'div316', 'div292', 'div269', 'div245', 'div246', 'div200', 'div177', 'div178', 'div179', 'div180', 'div181', 'div185', 'div186', 'div211', 'div212', 'div237', 'div238', 'div262', 'div310', 'div334', 'div358', 'div357', 'div381', 'div380', 'div379', 'div403', 'div402', 'div401', 'div396', 'div395', 'div394', 'div370', 'div369', 'div344', 'div320'];
  //   fireFirst(arcadeFire);
  // }, 3000);
  
  // var st1 = setTimeout(function () {
  //   //const arcadeFire = ['div315', 'div316', 'div320', 'div321', 'div325', 'div326', 'div328', 'div329', 'div333', 'div334'];
  //   const arcadeFire =['div371', 'div372', 'div349', 'div326', 'div282', 'div260', 'div237', 'div238', 'div214'];
  //   fireFirst(arcadeFire);
  // }, 3000);
  var st2 = setTimeout(function () {
    // const arcadeFire = ['div388', 'div364', 'div340', 'div316', 'div292', 'div269', 'div270', 'div247', 'div224', 'div225', 'div201', 'div202', 'div203', 'div180', 'div181', 'div208', 'div209', 'div210', 'div211', 'div212', 'div213', 'div237', 'div238', 'div261', 'div285', 'div309', 'div333', 'div357', 'div381', 'div380', 'div379', 'div378', 'div402', 'div378', 'div377', 'div352', 'div326', 'div350', 'div349', 'div373', 'div372', 'div396', 'div395', 'div370', 'div369', 'div345', 'div321', 'div298', 'div274', 'div275', 'div276', 'div277', 'div253', 'div254', 'div230', 'div205', 'div204', 'div179', 'div178', 'div177', 'div201', 'div224', 'div247'];
    const arcadeFire = ['div387', 'div363', 'div340', 'div316', 'div292', 'div269', 'div270', 'div247', 'div224', 'div201', 'div202', 'div203', 'div204', 'div205', 'div206', 'div208', 'div209', 'div210', 'div211', 'div212', 'div213', 'div237', 'div261', 'div260', 'div283', 'div282', 'div283', 'div284', 'div308', 'div309', 'div333', 'div357', 'div358', 'div382', 'div381', 'div405', 'div404', 'div403', 'div402', 'div401', 'div377', 'div353', 'div352', 'div374', 'div373', 'div372', 'div396', 'div395', 'div394', 'div369', 'div345', 'div321', 'div298', 'div299', 'div276', 'div277', 'div253', 'div230', 'div205', 'div180', 'div179', 'div202', 'div201', 'div225', 'div224', 'div247', 'div270', 'div269', 'div292', 'div316', 'div340', 'div364', 'div387'];
    // const arcadeFire = [
    //   'div185', 'div186', 'div187', 'div188', 'div189', 'div189', 'div188', 'div187', 'div186', 'div185', 'div181', 'div180', 'div179', 'div178', 'div177', 'div200', 'div201', 'div202', 'div203', 'div204', 'div205', 'div206', 'div208', 'div209', 'div210', 'div211', 'div212', 'div213', 'div214', 'div238', 'div237', 'div233', 'div232', 'div230', 'div229', 'div225', 'div224', 'div224', 'div225', 'div252', 'div253', 'div254', 'div260', 'div285', 'div285', 'div260', 'div284', 'div283', 'div282', 'div277', 'div276', 'div275', 'div274', 'div271', 'div270', 'div269', 'div292', 'div291', 'div291', 'div292', 'div320', 'div321', 'div301', 'div302', 'div306', 'div307', 'div308', 'div309', 'div310', 'div334', 'div333', 'div329', 'div328', 'div326', 'div325', 'div349', 'div345', 'div344', 'div340', 'div339', 'div363', 'div364', 'div368', 'div369', 'div370', 'div371', 'div372', 'div373', 'div374', 'div376', 'div377', 'div378', 'div379', 'div380', 'div381', 'div382', 'div405', 'div404', 'div403', 'div402', 'div401', 'div397', 'div396', 'div395', 'div394', 'div393', 'div388'];
    
    fireFirst(arcadeFire);
  }, 3400);

  var img = document.getElementById("scream");

  var img = new Image();
  img.src = "/wp-content/themes/r33/img/r33-6.jpg";
  //img.src = "/wp-content/themes/r33/img/r33-7.jpg";
  // img.src = "/wp-content/themes/r33/img/test-1.jpg";
  // img.src = "/wp-content/themes/r33/img/boom.jpg";
  
  // img.src = "/wp-content/themes/hektor/img/hektor.jpg";
  var ctx = null;
  img.onload = function () {
    
    const c = document.getElementById("r33Canvas");
    ctx = c.getContext("2d");
    //const img = document.getElementById("scream");
    ctx.drawImage(img, 0, 0);
    const imgData = ctx.getImageData(0, 0, 24, 24);
    var divD = '';
    //console.log(imgData.data);
    // invert colors
   
    var colorRgbaArray = [];
    var j = 0;
    for (let i = 0; i < imgData.data.length; i += 4) {
      j++;
      // if (i == 0) {
      //   console.log(imgData.data[0]);
      //   console.log(imgData.data[1]);
      //   console.log(imgData.data[2]);
      //   console.log(imgData.data[3]);
        //divD +='<div style="width=10px; height:10px; background:rgba(' + imgData.data[i] + ',' + imgData.data[i + 1] + ',' + imgData.data[i + 2] + ',1);">TEST</div>';

      //}


      var y = Math.sin(i);
      
      // i += increase;
      
      var rand = getRandomIntInclusive(-300, -13);
      var rand1 = getRandomIntInclusive(-80, 80);
      //console.log('i:'+i);
      var color = 'rgba(' + imgData.data[i] + ', ' + imgData.data[i + 1] + ', ' + imgData.data[i + 2] + ', 1)';
      var colorRgba = imgData.data[i] + '' + imgData.data[i + 1] + '' + imgData.data[i + 2];
      colorRgbaArray.push(color);
      //console.log(colorRgba);
      var css , Hclass = '';
      if (colorRgba >= 250) {
        //css = ' border-radius:50%; filter:blur(10px); background-color:#003cff !important;';
        Hclass = ' r33';
      } else {
        //css = ' background-color:#fff;';
        Hclass = 'none';
      }
      divD += '<div class=" div' + j + ' ' + Hclass + '" style="' + css + ' -animation-delay: ' + (i / 6) + 'ms; transform: translateY(' + rand + 'px); z-index:' + rand1 +'; -border-color:'+color+'; -color:' + color + '; -top:'+rand+'px;  -left:'+rand1+'px;  float:none; width:' + Psize + '; height:' + Psize + '; -background:' + color +';"></div>';
      // imgData.data[i] = 255 - imgData.data[i];
      // imgData.data[i + 1] = 255 - imgData.data[i + 1];
      // imgData.data[i + 2] = 255 - imgData.data[i + 2];
      // imgData.data[i + 3] = 255;
      
    }
    document.querySelector('body .wr').innerHTML += divD;
    ctx.putImageData(imgData, 0, 0);

    let divs = document.querySelectorAll('.wr > div');
    //console.log(divs);

    var dd = [];

    for (var i = 0; i < divs.length; i++) {
      // divs[i].addEventListener("mouseover", function (e) {
      //   e.currentTarget.classList.toggle('fire');
      // });
      divs[i].addEventListener("mouseover", function (e) {
        dd[i] = e.currentTarget;
        dd[i].classList.add('fire');
        
        var classI = dd[i].classList.item(0);
        //console.log("'" + classI + "',");
        output += "'" + classI + "',";
        timeOuter(1, dd[i], 1000);
        //console.log(output);
          

          
        
      });

      // divs[i].addEventListener('touchmove,touchstart', function (e) {
      //   dd[i] = e.currentTarget;
      //   dd[i].classList.add('fire');
      //   timeOuter(i, dd[i]);
      // })
      
     
    }
    // console.log('colorRgbaArray');
    // console.log(colorRgbaArray);
    // console.log('j');
    // console.log(j);

    randomlyRemoveFromArray(colorRgbaArray);
    
  };
  
 


  // document.querySelector('.button').addEventListener("click", function (e) {
  //   //alert('klik');

  //   var html = document.querySelector('body').innerHTML;
  //   //var html = '<div class="expertiment">'+experiment+'</div>';
  //   //alert(output);
  //   var output = '<h1 style="color:red; font-size:10rem;">TEST 123</h1><h1>TEST 123</h1><h1>TEST 123</h1><h1>TEST 123</h1>';
  //   var doc = document.implementation.createHTMLDocument("");
  //   doc.write(html);

  //   // You must manually set the xmlns if you intend to immediately serialize 
  //   // the HTML document to a string as opposed to appending it to a 
  //   // <foreignObject> in the DOM
  //   doc.documentElement.setAttribute("xmlns", doc.documentElement.namespaceURI);

  //   // Get well-formed markup
  //   html = (new XMLSerializer).serializeToString(doc);
    
  //   const canvas = document.getElementById("r33Canvas1");
  //   const ctx1 = canvas.getContext("2d");
  //   var data = '<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200">' +
  //     '<foreignObject width="100%" height="100%">' +
  //     output +
  //     '</foreignObject>' +
  //     '</svg>';

  //   var DOMURL = window.URL || window.webkitURL || window;

  //   var img1 = new Image();
  //   var svg = new Blob([data], { type: 'image/svg+xml;charset=utf-8' });
  //   var url = DOMURL.createObjectURL(svg);
  //   console.log(url);

  //   img1.onload = function () {
      
  //     ctx1.drawImage(img1, 1700, 1700);
  //     DOMURL.revokeObjectURL(url);
  //   }

  //   img1.src = url;
  //   console.log(img1.src);
  //   alert(img1.src);
  //   document.querySelector('#output').innerHTML = '<img src="' + img1.src + '"/>';
    
  // });

 


 
});





//import { DisplayLabel } from './components/DisplayLabel';



//import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
// import 'dark-mode-switch';
//import '/css/test.scss';

//alert('hoezo kappen hij doet yolo!!!');
//Import our custom CSS 
//import '../../css/src/main.scss';

//import './dark-mode-switch.js';


import Swiper from 'swiper/bundle';
//import Swiper from 'swiper';
//* import the Swiper styles
// import 'swiper/css';

// // import styles bundle
import 'swiper/css/bundle';





//https://swiperjs.com/get-started
//import Swiper from 'swiper';
  // import Swiper styles
  //import 'swiper/css';

  //const swiper = new Swiper(...);

//import Masonry from 'masonry-layout';

import barba from '@barba/core';

import gsap from "gsap";



//npm install flickity-imagesloadedimport PhotoSwipeLightbox from 'photoswipe/lightbox';

//https://www.lightgalleryjs.com/
// import lightGallery from 'lightgallery';

// // Plugins
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';

//import { tns } from "./node_modules/tiny-slider/src/tiny-slider"


//var msnry = new Masonry( '.grid', {});

// let Main = {
//   init: async function () {

//     // initialize demo javascript component - async/await invokes some 
//     //  level of babel transformation
//     const displayLabel = new DisplayLabel();
//     await displayLabel.init();

//   }
// };

// barba.init({
//   transitions: [{
//     name: 'default-transition',
//     leave() {
//       // create your stunning leave animation here
//       // console.log('Leave');
//       // document.body.classList.add("fade-out");
//     },
//     enter() {
//       // create your amazing enter animation here
//       // console.log('Enter');
//       // document.body.classList.add("fade-out-enter");
//       // st = setTimeout(function(){
//       //   document.body.classList.remove("fade-out-enter","fade-out");
//       // },1300);
      
//     }
//   }]
// });




function innitSwiper() {
  
  const swiper = new Swiper('.swiper', {
    // Optional parameters
    //direction: 'vertical',
    loop: true,
    autoHeight: true,
    //wrapperClass: 'wp-block-gallery',
    //slideClass: 'wp-block-image',
    //containerModifierClass:'wp-block-gallery',
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 3
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true
    },
  });
}


// myOffcanvas.addEventListener('hidden.bs.offcanvas', event => {
//   alert('boe');
// })

barba.init({
  timeout: 10000,
  requestError: (trigger, action, url, response) => {
    // go to a custom 404 page if the user click on a link that return a 404 response status
    if (action === 'click' && response.status && response.status === 404) {
      barba.go('/404');
    }

    // prevent Barba from redirecting the user to the requested URL
    // this is equivalent to e.preventDefault() in this context
    return false;
  },
  transitions: [{
    
    sync: true,
    name: 'opacity-transition-yolo',
    leave(data) {
      return gsap.to(data.current.container, {x: 0, y:0, duration: .25, opacity:0});
    },
    enter(data) {
      return gsap.from(data.next.container, {x: 0, y:0, duration: .4, opacity:0.4});
    }
  }]
});


barba.hooks.afterEnter((data) => {
  innitSwiper();
  //console.log(data.next.namespace);
  //alert('console.log(data.next.namespace);');
  var Pnav = document.getElementsByClassName('single-post-nav-wr')[0];
  var PnavHtml = Pnav.innerHTML;
  var Cnav = document.getElementsByClassName('outer-barba')[0];
  Cnav.innerHTML = '';
  Pnav.innerHTML = '';
  Pnav.remove();
  // console.log(Pnav);
  // console.log('!');
  Cnav.innerHTML = PnavHtml;
  

 

});

// var Flickity = require('flickity');
// require('flickity-imagesloaded');
// require('flickity-fullscreen');

//Main.init();


document.addEventListener("DOMContentLoaded", function() {
  
  

  document.querySelectorAll("#wpadminbar a").forEach(item=>item.setAttribute('data-barba-prevent','self'));
  var myOffcanvas = document.getElementById('navbarOffcanvasMd')
  var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

  
 
  
  var windowWidth = window.innerWidth;
  //console.log(windowWidth);
  if(windowWidth <= 767){
  
    const cbox = document.querySelectorAll(".menu-item");

    for (let i = 0; i < cbox.length; i++) {
      cbox[i].addEventListener("mousedown", function(){
        bsOffcanvas.toggle();
      });
    }

  }

  // var menuitem = document.querySelectorAll('.menu-item');
  // menuitem.addEventListener("mousedown", function(){
  //   alert('!');
  // });
  
  const highlightedItems = document.querySelectorAll(".portfolio-item");
 
  var st = setTimeout(function(){
    highlightedItems.forEach((userItem) => {
      userItem.classList.remove("hide-car");
    });
  },500);
});

//console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode');
}else{
  console.log('Looks like we are in production mode');
}
 